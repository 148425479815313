import { Button, Col, Container, Row } from 'react-bootstrap'
import { NextRouter, useRouter } from 'next/router'
import { ArrowLeft } from 'src/components/Icons'
import { AppLayout } from '..'

interface IProps {
  children: JSX.Element
  title: string
  next: (router: NextRouter) => void
  skippable?: boolean
  skipTo?: 'land-history'
}
function ModuleLayoutAffirm({ title, next, children }: IProps) {
  const router = useRouter()

  return (
    <AppLayout title={title}>
      <Container className='mt-4 margin_bottom-56'>
        {children}
        <div style={{ borderBottom: '1px solid #E4E4E6' }} className='margin_top-40 margin_bottom-56' />
        <Row className='  step-forwarder'>
          <Col className='d-flex justify-content-between'>
            <Button variant='primary' className='form_btn-no-outline' onClick={() => router.back()}>
              <ArrowLeft /> &nbsp; Back
            </Button>

            <div className='d-block d-md-flex gap-x-4 align-items-center'>
              <Button variant='primary' className='form_btn mt-md-0' onClick={() => next(router)}>
                Sign and Continue
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </AppLayout>
  )
}

export default ModuleLayoutAffirm
