import { useUserData } from 'src/helpers/hooks/user'
import { getNestedPath } from 'src/utils/ui'
import Link from 'next/link'
import { Logo } from './Icons'

function BurgerMenu() {
  const auth = useUserData()
  const orgId = auth.user?.organizations[0]?.id

  return (
    <div className='burger_menu'>
      <nav role='navigation'>
        <div id='menuToggle'>
          <input type='checkbox' />
          <Logo />
          <ul id='menu' className='list-unstyled padding_top-68 padding_left-68'>
            <li className='margin_bottom-24'>
              <Link href='/dashboard'>
                <a className='link_underline-none para-5 color_text-gray--01 fw-400 '>Home</a>
              </Link>
            </li>
            <li className='margin_bottom-24'>
              <Link href={getNestedPath(orgId, 'osps')}>
                <a className='link_underline-none para-5 color_text-gray--01 fw-400'>OSP</a>
              </Link>
            </li>
            <li className='margin_bottom-24'>
              <Link href={getNestedPath(orgId, 'activity-tracker')}>
                <a className='link_underline-none para-5 color_text-gray--01 fw-400'>Activities</a>
              </Link>
            </li>
            <li className='margin_bottom-24'>
              <Link href={getNestedPath(orgId, 'dms')}>
                <a className='link_underline-none para-5 color_text-gray--01 fw-400'>Documents</a>
              </Link>
            </li>
            <li className='margin_bottom-24'>
              <Link href='/help'>
                <a className='link_underline-none para-5 color_text-gray--01 fw-400'>Help</a>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  )
}

export default BurgerMenu
