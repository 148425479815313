import { useUserData } from 'src/helpers/hooks/user'
import { menuItems } from 'src/config/main-menu'
import { ListGroup } from 'react-bootstrap'
import { useRouter } from 'next/router'
import Link from 'next/link'

function Sidebar({ tabs, setTabs }: any) {
  const { pathname } = useRouter()

  const auth = useUserData()
  const orgId = auth.user?.organizations[0]?.id

  return (
    <ListGroup>
      {menuItems(orgId).map((menu, i) => (
        <Link href={menu.to} key={i}>
          <a
            className={
              tabs === menu.id ||
              (pathname.includes('dms') && menu.id === 'dms') ||
              (pathname.includes('osps') && menu.id === 'osps' && !pathname.includes('dms')) ||
              (menu.id === 'dashboard' && pathname.includes('dashboard')) ||
              (menu.id === 'help' && pathname.includes('help'))
                ? 'margin_bottom-24 list-group-item list-group-item-action active'
                : 'margin_bottom-24 list-group-item list-group-item-action'
            }
            onClick={() => setTabs(menu.id)}
          >
            <menu.icon />
            &nbsp;
            <span className='fw-300'>{menu.label} </span>
          </a>
        </Link>
      ))}
    </ListGroup>
  )
}

export default Sidebar
