import { DocumentIcon, HomeIcon, PaperWorkIcon, HelpIcon, ActivityTrackerIcon } from '../components/Icons'
import { getNestedPath } from 'src/utils/ui'

export const menuItems = (orgId: string) => [
  {
    id: 'dashboard',
    label: 'Home',
    to: '/dashboard',
    icon: HomeIcon,
  },
  {
    id: 'osps',
    label: 'OSP',
    to: getNestedPath(orgId, 'osps'),
    icon: PaperWorkIcon,
  },
  {
    id: 'activity-tracker',
    label: 'Activities',
    to: getNestedPath(orgId, 'activity-tracker'),
    icon: ActivityTrackerIcon,
  },
  {
    id: 'dms',
    label: 'Documents',
    to: getNestedPath(orgId, 'dms'),
    icon: DocumentIcon,
  },
  {
    id: 'help',
    label: 'Help',
    to: '/help',
    icon: HelpIcon,
  },
]
