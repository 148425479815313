import { startNProgress, stopNProgress } from '../nprogress'
import { destroyRefreshToken } from 'src/requests/auth'
import { getRefreshToken } from '../nookies'

export async function requestForLogout() {
  startNProgress()
  try {
    const refresh = getRefreshToken()
    await destroyRefreshToken(refresh)
  } catch (error) {
  } finally {
    stopNProgress()
  }
}
