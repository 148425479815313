import { getDoubleLettersName } from 'src/utils/ui'
import { Navbar, Dropdown } from 'react-bootstrap'
import { requestForLogout } from 'src/utils/auth'
import { UserHook } from 'src/interfaces/auth'
import { useRouter } from 'next/router'
import BurgerMenu from './DropMenu'
import { Logo } from './Icons'
import Link from 'next/link'

interface IProps {
  auth: UserHook
}

function Topbar({ auth }: IProps) {
  const { pathname } = useRouter()
  const router = useRouter()

  const logout = () => {
    requestForLogout().finally(() => {
      auth.logout()

      // Delete cookies
      document.cookie = 'refresh=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      document.cookie = 'access=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'

      router.replace('/login')
    })
  }

  return (
    <>
      <Navbar style={{ backgroundColor: '#fafafa' }}>
        <div>
          <BurgerMenu />
        </div>

        <Navbar.Brand href='/'>
          <div className='logo_container'>
            <Logo />
          </div>
        </Navbar.Brand>

        {!pathname.includes('profile') && (
          <Dropdown className='dropdown_account-icon'>
            <Dropdown.Toggle className='border-0 color_bg-primary'>
              <span>{getDoubleLettersName(auth.user?.name || '')}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu align={'end'} variant='outline'>
              <Link href='/dashboard/profile'>
                <a className='dropdown-item'>Account</a>
              </Link>
              <a className='dropdown-item pointer' onClick={() => logout()}>
                Sign Out
              </a>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Navbar>
      <div className='mobile_menu-border' />
    </>
  )
}

export default Topbar
