import { Container, Row, Col, Tab } from 'react-bootstrap'
import { useUserData } from 'src/helpers/hooks/user'
import Metadata from 'src/components/Metadata'
import Sidebar from 'src/components/SideBar'
import { ReactNode, useState } from 'react'
import Topbar from 'src/components/Topbar'

interface IProps {
  children: ReactNode | JSX.Element
  title: string
  to?: string
}

function AppLayout({ title, children, to }: IProps) {
  const [tabs, setTabs] = useState()
  const auth = useUserData()

  return (
    <div className='dashboard'>
      <Metadata title={title} />

      <Topbar auth={auth} />

      <Container fluid className='at-padding'>
        <Tab.Container id='dashboard-tabs' defaultActiveKey={to}>
          <Row className='at-height color_bg-background padding_right-10'>
            <Col
              sm={3}
              className='pt-5 position-fixed h-100 dashboard-sidebar
            color_bg-white padding_left-100'
              style={{ width: 300 }}
            >
              <Sidebar setTabs={setTabs} tabs={tabs} />
            </Col>

            <Col className='page_router color_bg-background padding_top-68'>
              {to && to === tabs ? (
                <Tab.Content>
                  <Tab.Pane eventKey={to}>{children}</Tab.Pane>
                </Tab.Content>
              ) : (
                children
              )}
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </div>
  )
}

export default AppLayout
