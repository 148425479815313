import { Button, Col, Container, Row } from 'react-bootstrap'
import { NextRouter, useRouter } from 'next/router'
import { ArrowLeft } from 'src/components/Icons'
import { AppLayout } from '..'
import { setNotApplicable } from 'src/requests/osp'

interface IProps {
  children: JSX.Element
  title: string
  next: (router: NextRouter) => void
  skippable?: boolean
  parcels?: boolean
  skipTo?: 'land-history'
  hide?: boolean
}
function ModuleLayout({ title, next, children, parcels, skippable, skipTo, hide }: IProps) {
  const router = useRouter()
  const { orgId, ospId, parcelId } = router.query
  console.log(parcelId)

  const manageParcels = () => {
    router.push(`/organizations/${orgId}/osps/${ospId}/land-history/parcel-history-list`)
  }

  const skipModule = async () => {
    if (title == 'Aqua Greenhouse') {
      console.log('Aqua Green house skip')
      await setNotApplicable(title, ospId as string)
    }
    router.push(`/organizations/${orgId}/osps/${ospId}${skipTo ? '/' + skipTo : ''}`)
  }

  return (
    <AppLayout title={title}>
      <Container className='mt-4'>
        {children}

        {!hide && <div style={{ borderBottom: '1px solid #E4E4E6' }} className='margin_top-40 margin_bottom-56' />}
        {!hide && (
          <Row className='  step-forwarder margin_bottom-65'>
            <Col className='d-flex justify-content-between'>
              <Button variant='primary' className='form_btn-no-outline' onClick={() => router.back()}>
                <ArrowLeft /> &nbsp; Back
              </Button>

              <div className='d-block d-md-flex gap-x-4 align-items-center'>
                {parcels && (
                  <Button variant='outline' className='form_btn-outline--primary margin_right-16 mr-xs-0 mr-md-2' onClick={manageParcels}>
                    Continue
                  </Button>
                )}
                {skippable && (
                  <Button variant='outline' className='form_btn-outline--primary margin_right-16 mr-xs-0 mr-md-2' onClick={skipModule}>
                    Not Applicable – Skip
                  </Button>
                )}
                {!parcels && (
                  <Button variant='primary' className='form_btn mt-md-0' onClick={() => next(router)} title='Continue'>
                    Continue
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </AppLayout>
  )
}

export default ModuleLayout
