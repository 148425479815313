import { Container, Col, Row, Button, Nav, Tab } from 'react-bootstrap'
import { useUserData } from 'src/helpers/hooks/user'
import { requestForLogout } from 'src/utils/auth'
import { ReactNode, useState } from 'react'
import { useRouter } from 'next/router'
import AppLayout from '../App'
import Link from 'next/link'

interface IProps {
  children: ReactNode | JSX.Element
  title: string
}

function ProfileLayout({ title, children }: IProps) {
  const [loggingout, setLoggingout] = useState(false)
  const { replace, asPath } = useRouter()
  const path = asPath.split('/')
  const auth = useUserData()

  const logout = async () => {
    setLoggingout(true)
    requestForLogout().finally(() => {
      auth.logout()
      if (typeof window !== 'undefined') {
        ;(window as unknown as any).cookieStore.delete('refresh')
        ;(window as unknown as any).cookieStore.delete('access')
      }
      replace('/login')
    })
    setLoggingout(false)
  }

  return (
    <AppLayout title={title}>
      <Container className='account_profile-container'>
        <Row>
          <Col xs={12}>
            <Row>
              <Col>
                <h2 className='color_text-gray--01 title-5 fw-400'>My Account</h2>
              </Col>
              <Col style={{ textAlign: 'right' }}>
                <Button variant='secondary' className='form_btn' onClick={() => logout()} disabled={loggingout}>
                  <b>Sign Out</b>
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <Tab.Container>
              <Row>
                <Col xs={12}>
                  <Nav variant='tabs' id='profile-tabs' onSelect={(selectedKey) => replace(`/dashboard/${selectedKey}`)}>
                    <Nav.Item style={{ display: 'inline-block', width: 'auto' }}>
                      <Link href='profile'>
                        <Nav.Link eventKey='profile' className={`${path[path.length - 1] === 'profile' ? 'active' : ''} pointer`}>
                          My Profile
                        </Nav.Link>
                      </Link>
                    </Nav.Item>

                    {auth?.user?.organizations[0]?.role == 'account owner' ? (
                      <>
                        <Nav.Item style={{ display: 'inline-block', width: 'auto' }}>
                          <Link href='manage-users'>
                            <Nav.Link eventKey='manage-users' className={`${path[path.length - 1] === 'manage-users' ? 'active' : ''} pointer`}>
                              Manage Users
                            </Nav.Link>
                          </Link>
                        </Nav.Item>
                        <Nav.Item style={{ display: 'inline-block', width: 'auto' }}></Nav.Item>
                      </>
                    ) : (
                      <></>
                    )}
                  </Nav>
                </Col>
              </Row>

              <Row>
                <Tab.Content>{children}</Tab.Content>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </AppLayout>
  )
}

export default ProfileLayout
