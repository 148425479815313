import { Button, Col, Container, Row } from 'react-bootstrap'
import { NextRouter, useRouter } from 'next/router'
import { AppLayout } from '..'
import { ArrowLeft } from 'src/components/Icons'

interface IProps {
  children: JSX.Element
  back: (router: NextRouter) => void
  buttonText: ButtonText
  next: (router: NextRouter, selectedOption?: string) => void
  title: string
}

type ButtonText = 'SoloBack' | 'SoloContinue' | 'Double'

function ButtonFooterLayout({ children, buttonText, next, title }: IProps) {
  const router = useRouter()

  const screenNavigation = (): void => {
    if (buttonText === 'SoloBack') {
      return router.back()
    }

    if (buttonText === 'SoloContinue') {
      return next(router)
    }
  }

  const singleButton = (
    <div className='d-flex at_btn-continue margin_bottom-12'>
      <Button variant='primary' className='form_btn' onClick={screenNavigation}>
        {buttonText === 'SoloBack' ? 'Back' : 'Continue'}
      </Button>
    </div>
  )

  const doubleButton = (
    <div className='d-flex at_btn-footer margin_bottom-12'>
      <Button variant='primary' className='form_btn-no-outline' onClick={() => router.back()}>
        <ArrowLeft /> &nbsp; Back
      </Button>
      <Button variant='outline' className='form_btn' onClick={() => next(router)}>
        Continue
      </Button>
    </div>
  )

  return (
    <AppLayout title={title}>
      <Container className='d-flex flex-column' style={{ height: '95%', paddingRight: '0 !important' }}>
        <div className=''>{children}</div>
        <Row className='mt-auto'>
          <hr style={{ borderColor: '#8A8D93' }} />
          <Col className='margin_bottom-100' style={{ paddingRight: '0' }}>
            {buttonText === 'Double' ? doubleButton : singleButton}
          </Col>
        </Row>
      </Container>
    </AppLayout>
  )
}

export default ButtonFooterLayout
